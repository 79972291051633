import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Alert, Container, Row, Col } from 'react-bootstrap';
import { Section } from '../../components/Core';
import { Link } from 'gatsby';
import API from '../../api/api';
import { RenderRecontractPricingBoxes } from './RenderRecontractPricingBoxes';
import { OdinProductSchema } from '../../utils/OdinProductSchema';
import GlobalContext from './../../context/GlobalContext';
import { findOfferParams } from '../../utils/helperFn';
import { useSearchParams } from 'react-router-dom';

const ButtonGroup = styled.div`
  border-radius: 50px;
  padding: 8px 2px;
  background-color: #fff;
  display: inline-flex;
  border: 2px solid #07131e;

  .btn {
    border-radius: 50px;
    border: 2px solid #07131e;
    height: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #19191b;
    margin: 0 6px;

    &.active {
      background: #08de9e;
      color: #19191b;
      box-shadow: none;
      border: 2px solid #07131e;
    }
  }
`;

const RecontractDynamicPricingSection = (props) => {
  const [productsToSelect, setProductsToSelect] = useState(null);
  const [productCategoryFilter, setProductCategoryFilter] = useState('BROADBAND');
  const [offerAPIError, setOfferAPIError] = useState(false);
  const gContext = useContext(GlobalContext);
  const offerParams = findOfferParams(props.searchParams);
  var urlLocation = 'residential';

  const renderAPIErrorInformation = () => (
    <Alert variant="danger" className="text-center">
      <Alert.Heading className="mt-4">We are experiencing issues with our servers</Alert.Heading>
      <p className="mt-4">
        We are currently unable to show you our latest offer. We kindly ask you to try again in few
        minutes.
      </p>
    </Alert>
  );

  const renderPricingSwitches = () => (
    <Row id="pricing-section" className={`${props.location === 'business' ? 'pt-0' : ''}`}>
      <Col sm="12" className="text-center mb-4">
        <ButtonGroup>
          <span
            role="button"
            className={`btn btn-filter ${
              gContext.productContractFilter === 'contractHigh' ? 'active' : ''
            }`}
            onClick={() => {
              gContext.setProductContractFilter('contractHigh');
            }}
            onKeyDown={() => {
              gContext.setProductContractFilter('contractHigh');
            }}
            tabIndex={0}
          >
            {gContext?.goGetRegistrationData()?.contractText}
          </span>
        </ButtonGroup>
      </Col>
    </Row>
  );

  const renderPricingBody = () => (
    <>
      <Row className="justify-content-center reverse-order-mobile pricing-body">
        {
          <>
            <RenderRecontractPricingBoxes
              rawOffer={props.fetchedOffer}
              productCategory={productCategoryFilter}
              contractType={
                OdinProductSchema[urlLocation][productCategoryFilter][
                  gContext.productContractFilter
                ].value
              }
              location={urlLocation}
              slug={'recontract'}
              checkoutLanding={props.checkoutLanding}
              lead={props.lead}
              sprn={props.sprn}
              fullName={props.fullName}
            />
            <div className="text-center">
              <small>
                Prices available until 31/05/25.
                <Link className="underlined" to="/legal/website-terms-and-conditions">
                  Terms and conditions
                </Link>{' '}
                apply.
              </small>
            </div>
          </>
        }
      </Row>
    </>
  );

  return (
    <Section className={`pricing-section light-bg pricing-section-${props.location}`}>
      <Container>
        {offerAPIError ? renderAPIErrorInformation() : renderPricingSwitches()}
        {offerAPIError ? <div /> : renderPricingBody()}
      </Container>
    </Section>
  );
};

export default RecontractDynamicPricingSection;
